import { Injectable } from '@angular/core';
import { CartItem, Product, Cart, CheckoutDetails } from './dataservice.service';
import { BehaviorSubject } from 'rxjs';
import { ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { UtilService } from '../util/util.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {
    cart: BehaviorSubject<Cart> = new BehaviorSubject<Cart>(new Cart());

    constructor(
        private u: UtilService,
        private toast: ToastController,
        private storage: Storage,
      ) {
        this.getCart();
      }

    _loadCart() {
        return new Promise<Cart>(resolve => {
            this.storage.get('cart').then(data => {
                if (!!data && data !== '') {
                    let decodedCart: Cart = JSON.parse(data);
                    resolve(decodedCart);   
                } else {
                    resolve(new Cart());
                }
            });
        });
    }

    _saveCart(cart: Cart) {
        return new Promise<Cart>(resolve => {
            // Update total quantity
            cart.totalItems = cart.items.map(cartItem => cartItem.quantity).reduce((a, b) => a + b, 0);

            // Update checkout details
            this.updateCheckoutDetails(cart).then(cartres => {
                // console.log('checkoutDetails', cart.checkoutDetails);
                // Save to storage
                this.storage.set('cart', JSON.stringify(cartres)).then(x => {
                    this.cart.next(cartres);
                    resolve(cartres);
                    // console.log('cart updated', cartres);
                });
            });
        });
    }

    getCart() {
        this.storage.get('cart').then(async data => {
            if (!!data && data !== '') {
                let decodedCart: Cart = JSON.parse(data);
                this.cart.next(decodedCart);
            } else {
                this.cart.next(new Cart());
            }
        });
    }

    emptyCart() {
        this._saveCart(new Cart()).then(res => {
            this.u.toast('Carrello svuotato');
        });
    }

    addToCart(item: Product, itemQuantity: number = 1) {
        // console.log('Adding to cart...', item);
        this._loadCart().then(cart => {
            if (!cart) {
                cart = new Cart();
            }
            let found = cart.items.filter(c => c.product.id === item.id);
            if (!!found && !!found[0]) {
                found[0].quantity += itemQuantity;
            } else {
                let cartItem: CartItem = {
                    product: item,
                    quantity: itemQuantity
                };
                cart.items.push(cartItem);
            }
            this._saveCart(cart).then(res => {
                this.u.toast(itemQuantity === 1 ? 'Prodotto aggiunto al carrello' : 'Prodotti aggiunti al carrello');
            });
        });
    }

    addToCartMultiple(items: Array<Product>) {
        // console.log('Adding to cart...', item);
        this._loadCart().then(cart => {
            if (!cart) {
                cart = new Cart();
            }

            for (let i = 0; i < items.length; i++) {
                let item = items[i];

                let found = cart.items.filter(c => c.product.id === item.id);
                if (!!found && !!found[0]) {
                    found[0].quantity++;
                } else {
                    let cartItem: CartItem = {
                        product: item,
                        quantity: 1
                    };
                    if (cartItem.product.stock==0)
                    {
                        cart.items.push(cartItem);
                    }
                }
            }

            this._saveCart(cart).then(res => {
                this.u.toast('Prodotti aggiunti al carrello');
            });
        });
    }

    removeFromCart(item: Product) {
        this._loadCart().then(cart => {
            cart.items = cart.items.filter(c => c.product.id !== item.id);
            this._saveCart(cart).then(res => {
                this.u.toast('Prodotto rimosso dal carrello');
            });
        });
    }

    removeFromCartMultiple(items: Array<Product>) {
        this._loadCart().then(cart => {
            for (let i = 0; i < items.length; i++) {
                let item = items[i];
                cart.items = cart.items.filter(c => c.product.id !== item.id);
            }
            this._saveCart(cart).then(res => {
                this.u.toast('Prodotti rimossi dal carrello');
            });
        });
    }

    changeQuantity(item: Product, quantity: number) {
        this._loadCart().then(cart => {
            let found = cart.items.filter(c => c.product.id === item.id);
            if (!!found && !!found[0]) {
                found[0].quantity = quantity;
            }
            this._saveCart(cart);
        });
    }

    round(num: number) {
        return parseFloat(num.toFixed(2));
    }

    // CHECKOUT
  updateCheckoutDetails(cart: Cart): Promise<Cart> {
        return new Promise(resolve => {

        let results = [
            0.00,
            this.calcolaImponibile(cart.items),
            this.calcolaIVA(cart.items),
            this.calcolascIVA20(cart.items),
            this.calcolascIVA10(cart.items)
        ];

        // cart.checkoutDetails.buonoSconto = results[0];
        cart.checkoutDetails.imponibileF = this.round(results[1]);
        cart.checkoutDetails.imponibilesc20 = this.round(results[1] * 0.8);
        cart.checkoutDetails.imponibilesc10 = this.round(results[1] * 0.9);
        cart.checkoutDetails.ivaF = this.round(results[2]);
        cart.checkoutDetails.ivasc20 = this.round(results[3]);
        cart.checkoutDetails.ivasc10 = this.round(results[4]);
        cart.checkoutDetails.totF = this.round(results[1] + results[2]);
        cart.checkoutDetails.totsc20 = this.round((results[1] * 0.8) + results[3]);
        cart.checkoutDetails.totsc10 = this.round((results[1] * 0.9) + results[4]);

        // Discount
        // // Reset
        // cart.checkoutDetails.useDiscount10 = false;
        // cart.checkoutDetails.useDiscount30 = false;
        // cart.checkoutDetails.useDiscount20 = false;
        // cart.checkoutDetails.useDiscountBonus = false;

        // Check
        cart.checkoutDetails.canUseDiscountBonus = (cart.checkoutDetails.buonoSconto >= cart.checkoutDetails.imponibileF && cart.checkoutDetails.imponibileF !== 0.00);
        cart.checkoutDetails.canUseDiscount30 = cart.checkoutDetails.totF >= 30.00;
        cart.checkoutDetails.canUseDiscount20 = cart.checkoutDetails.totF >= 30.00;
        cart.checkoutDetails.canUseDiscount10 = cart.checkoutDetails.totF < 30.00;

        // Apply
        if (cart.checkoutDetails.useDiscountBonus) {
            cart.checkoutDetails.useDiscount30 = false;
            cart.checkoutDetails.useDiscount20 = false;
            cart.checkoutDetails.useDiscount10 = false;
            // cart.checkoutDetails.imponibileF = 0.00;
            // cart.checkoutDetails.totF = cart.checkoutDetails.ivaF;
        }

        if (cart.checkoutDetails.useDiscount30) {
            cart.checkoutDetails.useDiscountBonus = false;
            cart.checkoutDetails.useDiscount20 = false;
            cart.checkoutDetails.useDiscount10 = false;
        }

        if (cart.checkoutDetails.useDiscount20) {
            cart.checkoutDetails.useDiscount30 = false;
            cart.checkoutDetails.useDiscountBonus = false;
            cart.checkoutDetails.useDiscount10 = false;
            // cart.checkoutDetails.imponibileF = cart.checkoutDetails.imponibilesc20;
            // cart.checkoutDetails.ivaF = cart.checkoutDetails.ivasc20;
            // cart.checkoutDetails.totF = cart.checkoutDetails.totsc20;
        }

        if (cart.checkoutDetails.useDiscount10) {
            cart.checkoutDetails.useDiscount30 = false;
            cart.checkoutDetails.useDiscount20 = false;
            cart.checkoutDetails.useDiscountBonus = false;
            // cart.checkoutDetails.imponibileF = cart.checkoutDetails.imponibilesc10;
            // cart.checkoutDetails.ivaF = cart.checkoutDetails.ivasc10;
            // cart.checkoutDetails.totF = cart.checkoutDetails.totsc10;
        }

        cart.checkoutDetails.discountApplied = cart.checkoutDetails.useDiscountBonus || cart.checkoutDetails.useDiscount30 || cart.checkoutDetails.useDiscount20 || cart.checkoutDetails.useDiscount10;

        resolve(cart);
    });
  }

  calcolaImponibile(cart: Array<CartItem>): number {
    let res = 0.00;
    for (let i = 0; i < cart.length; i++) {
      let cartItem = cart[i];
      res += cartItem.quantity * cartItem.product.cost_price;
    }
    return res;
  }

  calcolaIVA(cart: Array<CartItem>): number {
    let res = 0.00;
    for (let i = 0; i < cart.length; i++) {
      let cartItem = cart[i];
      let num2 = cartItem.quantity * cartItem.product.cost_price;
      let num3 = cartItem.product.vat / 100.00;
      res += num3 * num2;
    }
    return res;
  }

  calcolascIVA20(cart: Array<CartItem>): number {
    let res = 0.00;
    for (let i = 0; i < cart.length; i++) {
      let cartItem = cart[i];
      let num2 = cartItem.quantity * cartItem.product.cost_price;
      num2 = (num2 * 80.00) / 100.00;
      let num3 = cartItem.product.vat / 100.00;
      res += num3 * num2;
    }
    return res;
  }

  calcolascIVA10(cart: Array<CartItem>): number {
    let res = 0.00;
    for (let i = 0; i < cart.length; i++) {
      let cartItem = cart[i];
      let num2 = cartItem.quantity * cartItem.product.cost_price;
      num2 = (num2 * 90.00) / 100.00;
      let num3 = cartItem.product.vat / 100.00;
      res += num3 * num2;
    }
    return res;
  }

  resetDiscount() {
    this._loadCart().then(cart => {
        cart.checkoutDetails.useDiscountBonus = false;                
        cart.checkoutDetails.useDiscount30 = false;
        cart.checkoutDetails.useDiscount20 = false;
        cart.checkoutDetails.useDiscount10 = false;
        this._saveCart(cart);
    });
  }

  setDiscountBonus(discountBonus: number) {
    this._loadCart().then(cart => {
        cart.checkoutDetails.buonoSconto = this.round(discountBonus);
        this._saveCart(cart);
    });
  }

  useDiscountBonus() {
    this._loadCart().then(cart => {
        cart.checkoutDetails.useDiscountBonus = true;                
        cart.checkoutDetails.useDiscount30 = false;
        cart.checkoutDetails.useDiscount20 = false;
        cart.checkoutDetails.useDiscount10 = false;
        this._saveCart(cart);
    });
  }

  useDiscount30() {
    this._loadCart().then(cart => {
        cart.checkoutDetails.useDiscountBonus = false;                
        cart.checkoutDetails.useDiscount30 = true;
        cart.checkoutDetails.useDiscount20 = false;
        cart.checkoutDetails.useDiscount10 = false;
        this._saveCart(cart);
    });
  }

  useDiscount20() {
    this._loadCart().then(cart => {
        cart.checkoutDetails.useDiscountBonus = false;                
        cart.checkoutDetails.useDiscount30 = false;
        cart.checkoutDetails.useDiscount20 = true;
        cart.checkoutDetails.useDiscount10 = false;
        this._saveCart(cart);
    });
  }

  useDiscount10() {
    this._loadCart().then(cart => {
        cart.checkoutDetails.useDiscountBonus = false;                
        cart.checkoutDetails.useDiscount30 = false;
        cart.checkoutDetails.useDiscount20 = false;
        cart.checkoutDetails.useDiscount10 = true;
        this._saveCart(cart);
    });
  }
}