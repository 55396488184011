/**
* Ionic Full App  (https://store.enappd.com/product/ionic-full-app-ionic-4-full-app)
*
* Copyright © 2019-present Enappd. All rights reserved.
*
* This source code is licensed as per the terms found in the
* LICENSE.md file in the root directory of this source tree.
*/


import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-connection-check',
  templateUrl: './connection-check.page.html',
  styleUrls: ['./connection-check.page.scss'],
})
export class ConnectionCheckPage {
  constructor() {}
}
