import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataserviceService {
  current_user_details: any;
  current_user: any;

  item_tab: Array<HomeTab> = [
    { title: 'Overview' },
    { title: 'Related' },
    { title: 'Product Reviews' }
  ];

  cart: any;

  constructor() { 
    this.current_user = {
      id:5
    }  
  }

  card: NotificationsCard = {
    image: 'assets/images/products/1.jpg',
    title: 'Kya aapne kabhi online hotel book kia hai???\nHotel? Sastago',
    time: 9
  };

  notifications: Notification = {
    all: [
      this.card,
      this.card,
      this.card,
      this.card,
      this.card,
      this.card,
      this.card
    ],
    deals: [
      this.card,
      this.card,
      this.card,
      this.card,
      this.card,
      this.card,
      this.card
    ],
    orders: [],
    others: [
      this.card,
      this.card,
      this.card,
      this.card,
      this.card,
      this.card,
      this.card
    ]
  };

  trending = [
    'jacket',
    'drone',
    'shoes for men',
    'car accessories',
    'blazer for men',
    'watches men',
    'sports shoes for men',
    'earphone bluetooth',
    'jackets for men',
    'memory card'
  ];

  tabs: Array<HomeTab> = [
    { title: 'Popular' },
    { title: 'Recently Viewed' },
    { title: 'Fashion' },
    { title: 'Tops' },
    { title: 'Shoes' },
    { title: 'Automotive' },
    { title: 'Bottoms' },
    { title: 'Watches' },
    { title: 'Wallets & Bags' },
    { title: 'Accessories' },
    { title: 'Gadgets' },
    { title: 'Hobbies' },
    { title: 'Phone Upgrades' },
    { title: 'Home Decor' }
  ];
}

export interface HomeTab {
  title: string;
}

export interface NotificationsCard {
  image: string;
  title: string;
  time: number;
}

export interface Notification {
  all: Array<NotificationsCard>;
  deals: Array<NotificationsCard>;
  orders: Array<NotificationsCard>;
  others: Array<NotificationsCard>;
}

export interface Category {
  id: string;
  name: string;
  images: Array<string>;
}

export interface Product {
  id: string;
  name: string;
  images: Array<string>;
  size: string;
  color: string;
  cost_price: number;
  total_price: number; 
  full_price: number; 
  vat: number;
  discount: number;
  offer: boolean;
  stock: number;
  description: string;
  currency: string;
  bought: number;
  shipping: number;
  rating: number;
  rating_count: number;
  store_rate: number;
  store_rating: number;
  store_rating_count: number;
  sold_by: string;
  specs: string;
  reviews: Array<Review>;
  store_reviews: Array<Review>;
  sizing: {
    small: number;
    okay: number;
    large: number;
  };
  buyer_guarantee: string;
  sponsored: Array<Product>;
  quantity: number;
}

export interface Review {
  image: string;
  name: string;
  comment: string;
  rating: number;
  images: Array<string>;
}

export interface Cart {
  totalItems: number;
  items: Array<CartItem>;
  checkoutDetails: CheckoutDetails;
}

export interface CartItem {
  product: Product;
  quantity: number;
}

export interface CheckoutDetails {
  imponibileF: number;
  imponibilesc20: number;
  imponibilesc10: number;
  ivaF: number;
  ivasc20: number;
  ivasc10: number;
  totF: number;
  totsc20: number;
  totsc10: number;

  buonoSconto: number;
  canUseDiscountBonus: boolean;
  useDiscountBonus: boolean;

  canUseDiscount30: boolean;
  useDiscount30: boolean;

  canUseDiscount20: boolean;
  useDiscount20: boolean;

  canUseDiscount10: boolean;
  useDiscount10: boolean;

  discountApplied: boolean;
}

export class CheckoutDetails implements CheckoutDetails {
  constructor() {
    this.buonoSconto = 0.00;
    this.imponibileF = 0.00;
    this.imponibilesc20 = 0.00;
    this.imponibilesc10 = 0.00;
    this.ivaF = 0.00;
    this.ivasc20 = 0.00;
    this.ivasc10 = 0.00;
    this.totF = 0.00;
    this.totsc20 = 0.00;
    this.totsc10 = 0.00;

    this.canUseDiscountBonus = false;
    this.useDiscountBonus = false;

    this.canUseDiscount30 = false;
    this.useDiscount30 = false;

    this.canUseDiscount20 = false;
    this.useDiscount20 = false;

    this.canUseDiscount10 = false;
    this.useDiscount10 = false;

    this.discountApplied = false;
  }
}

export class Cart implements Cart {
  constructor() {
    this.totalItems = 0;
    this.items = new Array<CartItem>();
    this.checkoutDetails = new CheckoutDetails();
  }
}

export interface User {
  fname: string;
  lname: string;
  email: string;
  address: Array<Address>;
  billing: Array<any>;
  uid: string;
  did: string;
  aid: string;
}

export interface Address {
  first_name: string;
  last_name: string;
  address_line_1: string;
  address_line_2: string;
  country: string;
  state: string;
  city: string;
  zipcode: number;
  phone_number: number;
}

export interface Orders {
  product: Product;
  order_date: Date;
  id: string;
  amount: number;
  delivery_date: Date;
  status: string;
  billing_address: Address;
  shipping_address: Address;
  tax: number;
}

export interface Order {
  date: string;
  time: string;
  id: string;
  amount: number;
  discountM: number;
  discountU: number;
  cart: Array<CartItem>
  // delivery_date: Date;
  // status: string;
  // billing_address: Address;
  // shipping_address: Address;
  // tax: number;
}

export class Order implements Order {
  
}