import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { reject } from 'q';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UtilService } from '../util/util.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
    loggedUser: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    apiurl: string = environment.config.apiUrl;

    constructor(
        private u: UtilService,
        private http: HttpClient,
        private toast: ToastController,
        private storage: Storage,
    ) {
        this.getUser();
    }

    isLogged(): boolean {
        return !!this.loggedUser.value;
    }

    isPrivato(): boolean {

        if (this.loggedUser.value.type == 'privato')
            return true;
        return false;
    }

    getListino(): string {
        if (this.loggedUser.value)
            return this.loggedUser.value.listino;
        return "3";
    }

    _loadUser() {
        return new Promise<User>(resolve => {
            this.storage.get('user').then(data => {
                if (!data && data === "") {
                    resolve(null);
                }

                let decodedUser: User = JSON.parse(data);
                resolve(decodedUser);
            });
        });
    }

    _saveUser(userData: User) {
        return new Promise<User>(resolve => {
            this.storage.set('user', JSON.stringify(userData)).then(x => {
                this.loggedUser.next(userData);
                resolve(userData);
            });
        });
    }

    getUser() {
        this.storage.get('user').then(async data => {
            if (!data && data === "") {
                console.log("getUserNULL");
                this.loggedUser.next(null);
                return;
            }
            console.log("getUserRud");
            let decodedUser: User = JSON.parse(data);
            this.loggedUser.next(decodedUser);
        });
    }

    login(username: string, password: string) {
        return new Promise<User>(resolve => {
            this.http
              .get(`${this.apiurl}/checklogin_v3.php?user=${username}&psw=${password}`)
              .subscribe(async (x: any) => {
                  if (x.Res === 'true') {
                    let userData = this.toUser(x, username, password);
                    this._saveUser(userData).then(ud => {
                        resolve(userData);
                    });
                  } else {
                    this._saveUser(null).then(ud => {
                        this.u.toast('Username o password non validi');
                        reject(null);
                    });
                  }
              });
          });
    }

    toUser(rawUser: any, username: string, password: string): User {
        if (!!rawUser) {
            let user: User = {
                id: rawUser.Cliente.trim(),
                name: rawUser.Nome.trim(),
                username: username,
                password: password,
                type: rawUser.Tipo.trim(),
                agente: rawUser.Agente.trim(),
                listino: rawUser.Listino.trim()
            };
            return user;
        } else {
            return null;
        }
    }

    logout() {
        return new Promise<boolean>(resolve => {
            this._saveUser(null).then(ud => {
                resolve(true);
            });
        });
    }

    authenticate() {
        return new Promise<boolean>(resolve => {
            this._loadUser().then(u => {
                if (!u) {
                    this.u.toast('Username o password non validi');
                    resolve(false);
                }

                this.http
                .get(`${this.apiurl}/checklogin.php?user=${u.username}&psw=${u.password}`)
                .subscribe(async (x: any) => {
                    if (x.Res) {
                      resolve(true);
                    } else {
                        this._saveUser(null).then(ud => {
                            this.u.toast('Username o password non validi');
                            resolve(false);
                        });
                    }
                });
            });
        });
    }
}

export interface User {
    id: string;
    name: string;
    username: string;
    password: string;
    type: string;
    agente: string;
    listino: string;
}