/**
* Ionic Full App  (https://store.enappd.com/product/ionic-full-app-ionic-4-full-app)
*
* Copyright © 2019-present Enappd. All rights reserved.
*
* This source code is licensed as per the terms found in the
* LICENSE.md file in the root directory of this source tree.
*/


import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { CartService } from 'src/app/services/woocommerce/cart.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Order } from 'src/app/services/woocommerce/dataservice.service';

@Component({
  selector: 'app-orderinfo',
  templateUrl: './orderinfo.page.html',
  styleUrls: ['./orderinfo.page.scss'],
})
export class OrderinfoPage implements OnInit {
public order: Order;
  constructor(
    public authService: AuthService,
    protected cartService: CartService,
    private navParams: NavParams,
    private modalController: ModalController,
  ) { 
    this.order = navParams.get('value');
  }

  ngOnInit() {
  }
  dismiss() {
    this.modalController.dismiss();
  }

  addToCart(product) {
    this.cartService.addToCart(product);
  }

  addAllToCart() {
    this.cartService.addToCartMultiple(this.order.cart.map(x => x.product));
  }
}
