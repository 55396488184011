/**
* Ionic Full App  (https://store.enappd.com/product/ionic-full-app-ionic-4-full-app)
*
* Copyright © 2019-present Enappd. All rights reserved.
*
* This source code is licensed as per the terms found in the
* LICENSE.md file in the root directory of this source tree.
*/
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GuardsService } from './guards/guards.service';

const routes: Routes = [
  { path: '', redirectTo: 'e-store/categories', pathMatch: 'full' },
  { path: 'about', loadChildren: './pages/about/about.module#AboutPageModule' },
  { path: 'login', loadChildren: './pages/auth/login/login.module#LoginPageModule' },
  { path: 'connection-check', loadChildren: './pages/utils/connection-check/connection-check.module#ConnectionCheckPageModule' },
  { path: 'e-store/products', loadChildren: './pages/woocommerce/woocommerce-products/woocommerce-products.module#WoocommerceProductsPageModule' },
  { path: 'e-store/categories', loadChildren: './pages/woocommerce/woocommerce-categories/woocommerce-categories.module#WoocommerceCategoriesPageModule' },
  { path: 'e-store/product-details', loadChildren: './pages/woocommerce/product-details/product-details.module#ProductDetailsPageModule' },
  { path: 'e-store/cart', loadChildren: './pages/woocommerce/cart/cart.module#CartPageModule' },
  { path: 'e-store/orders', loadChildren: './pages/woocommerce/orders/orders.module#OrdersPageModule' },
  { path: 'e-store/orderinfo', loadChildren: './pages/woocommerce/orderinfo/orderinfo.module#OrderinfoPageModule' },
  { path: 'e-store/checkout', loadChildren: './pages/woocommerce/checkout/checkout.module#CheckoutPageModule' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
