/**
* Ionic Full App  (https://store.enappd.com/product/ionic-full-app-ionic-4-full-app)
*
* Copyright © 2019-present Enappd. All rights reserved.
*
* This source code is licensed as per the terms found in the
* LICENSE.md file in the root directory of this source tree.
*/
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicSwingModule } from './components/ionic-swing/ionic-swing.module';
import { Device } from '@ionic-native/device/ngx';
import { UtilService } from './services/util/util.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Network } from '@ionic-native/network/ngx';
import { ConnectivityService } from './services/network/connectivity.service';
import { Base64 } from '@ionic-native/base64/ngx';
import { WooCommerceService } from './services/woocommerce/woo-commerce.service';
import { FunctionService } from './services/woocommerce/function.service';
import { DataserviceService } from './services/woocommerce/dataservice.service';
import { IonicStorageModule } from '@ionic/storage';
import { OrderinfoPage } from './pages/woocommerce/orderinfo/orderinfo.page';
import { SwingModule } from 'angular2-swing';
import { CartService } from './services/woocommerce/cart.service';
import { AuthService } from './services/auth/auth.service';
import { ConnectionCheckPage } from './pages/utils/connection-check/connection-check.page';
import { FCM } from '@ionic-native/fcm/ngx';

@NgModule({
  declarations: [
    AppComponent, 
    OrderinfoPage,
    ConnectionCheckPage
  ],
  entryComponents: [
    OrderinfoPage,
    ConnectionCheckPage
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    IonicSwingModule,
    SwingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    FCM,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Device,
    Base64,
    UtilService,
    InAppBrowser,
    Network,
    ConnectivityService,
    WooCommerceService,
    FunctionService,
    DataserviceService,
    CartService,
    AuthService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
