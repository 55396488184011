import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { environment } from 'src/environments/environment';
import { Product, Category, Orders, Order, CartItem, CheckoutDetails, Cart } from './dataservice.service';
import { TestComponentRenderer } from '@angular/core/testing';
import { AuthService } from '../auth/auth.service';
import { reject } from 'q';
import { Observable, forkJoin } from 'rxjs';
import { CartService } from './cart.service';

@Injectable({
  providedIn: 'root'
})
export class WooCommerceService {
  apiurl: string = environment.config.apiUrl;

  constructor(
    private cartService: CartService,
    private authService: AuthService,
    private http: HttpClient
    ) { }

  // CATEGORIES/PRODUCTS
  getCategories() {
    return `${this.apiurl}/getcategories.php`;
  }

  getNews() {
    return `${this.apiurl}/getIKINews.php`;
  }

  getMessage() {
    return `${this.apiurl}/getIKIMessage.php`;
  }

  getExpenses(cliente: string) {
    return `${this.apiurl}/getproducts_my_expenses_v3.php?client=`+cliente;
  }

  getProductsByCategory(categoryId: string) {
    let listino=this.authService.getListino();
    console.log(listino);
    return `${this.apiurl}/getproducts_v3.php?cat=` + categoryId + `&listino=`+listino;
  }

  getProduct(cid, pid) {
    
    return new Promise<Product>(resolve => {
      let listino=this.authService.getListino();
      this.http
        .get(
          `${this.apiurl}/getproductdetails_v2.php?cat=${cid}&cod=${pid}`+ `&listino=`+listino
        )
        .subscribe((x: any) => {
          resolve(this.toProduct(x));
        });
    });
  }

  toCategory(rawCategory: any): Category {
    let category: Category = {
      id: rawCategory.Name.trim(),
      name: rawCategory.Details.trim(),
      images: [
        'https://api.dolcetorino.ikigai-soluzioni.it/immagini/' + rawCategory.Name.trim() + '.jpg'
      ]
    };
    return category;
  }

  toProduct(rawProduct: any): Product {
    let product: Product = {
      id: rawProduct.Name.trim(),
      name: rawProduct.Name.trim(),
      cost_price: parseFloat(rawProduct.Price),
      total_price:  Math.round((parseFloat(rawProduct.Price) + parseFloat(rawProduct.Price) /100 * parseInt(rawProduct.IVA))*100)/100 ,
      full_price: parseFloat(rawProduct.PriceFull),
      vat: parseInt(rawProduct.IVA),
      discount: 0,
      offer: false,
      stock: 0,
      description: rawProduct.Description.trim() + rawProduct.Ingredients.trim() ,
      images: 
        //'https://api.dolcetorino.ikigai-soluzioni.it/immagini/' + rawProduct.Name.trim() + '.jpg', 'https://api.dolcetorino.ikigai-soluzioni.it/immagini/' + rawProduct.Name.trim() + '-1.jpg', 'https://api.dolcetorino.ikigai-soluzioni.it/immagini/' + rawProduct.Name.trim() + '-2.jpg'
        rawProduct.Images
      ,
      rating_count: 0,
      store_rating_count: 0,
      currency: '€',
      bought: 0,
      size: '',
      color: '',
      shipping: 0,
      rating: 0,
      store_rating: 0,
      store_rate: 0,
      sold_by: '',
      specs: '',
      reviews: [],
      store_reviews: [],
      sizing: { small: 0, okay: 0, large: 0 },
      buyer_guarantee: '',
      sponsored: [],
      quantity: 1
    };
    return product;
  }

  JSON_to_URLEncoded(element, key?, list?) {
    let new_list = list || [];
    if (typeof element === "object") {
      for (let idx of Object.keys(element)) {
        this.JSON_to_URLEncoded(
          element[idx],
          key ? key + "[" + idx + "]" : idx,
          new_list
        );
      }
    } else {
      new_list.push(key + "=" + encodeURIComponent(element));
    }
    return new_list.join("&");
  }

  // ORDER
  getPastOrders(customerId) {
    return new Promise<Array<Order>>(resolve => {
      this.http.get(`${this.apiurl}/getorders.php?client=${customerId}`)
        .subscribe((x: Array<any>) => {
          resolve(x.map(o => this.toOrder(o)));
        });
    });
  }

  getOrderDetails(customerId, orderId) {
    return new Promise<Array<CartItem>>(resolve => {
      let listino=this.authService.getListino();
      this.http.get(`${this.apiurl}/getorderdetail_v2.php?client=${customerId}&codordine=${orderId}&listino=`+listino)
        .subscribe((x: Array<any>) => {
          let items = !!x ? x.map(o => this.toCart(o)) : [];
          resolve(items);
        });
    });
  }

  zeroFill(number, width) {
    width -= number.toString().length;
    if (width > 0) {
      return new Array( width + (/\./.test( number ) ? 2 : 1) ).join( '0' ) + number;
    }

    return number + ""; // always return a string
  }

  createOrder(cart: Cart) {
    return new Promise<string>(resolve => {
      let orderId: string;
      let loggedUser = this.authService.loggedUser.value;

      // Crea testata ordine
      this.http.post(`${this.apiurl}/buyorder_psw.php?client=${loggedUser.id}`, {
        psw: this.authService.loggedUser.value.password,
        user: this.authService.loggedUser.value.username,
      })
      .subscribe((boRes: any) => {
        // console.log('buyorder', boRes);
        if (boRes.Res !== 'true') {
          reject('Ci sono problemi con la connessione al server.');
        }

        orderId = boRes.Nome;
        // orderId = this.zeroFill(boRes.Nome, 9);
        // console.log('orderId new id', orderId);

        // Init coefficiente sconto
        let coefficienteSconto: number = 1.0; // Buono sconto o sconto merce 30%
        if (cart.checkoutDetails.useDiscount20) {
          coefficienteSconto = 0.8; // Sconto cassa 20%
        }
        if (cart.checkoutDetails.useDiscount10) {
          coefficienteSconto = 0.9; // Sconto immediato 10%
        }

        // Righe ordine
        let fxQueue: Array<Observable<any>> = new Array<Observable<any>>();
        for (let i = 0; i < cart.items.length; i++) {
          let cartItem = cart.items[i];
          let importo = cart.checkoutDetails.useDiscountBonus ? 0.00 : cartItem.product.cost_price * coefficienteSconto * cartItem.quantity;
          let uriParams = `cod=${orderId}&nr=${i + 1}&client=${loggedUser.id}&art=${cartItem.product.id}&des=${cartItem.product.description}&qta=${cartItem.quantity}.00&prezzo=${(cartItem.product.cost_price * coefficienteSconto).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits : 2, minimumFractionDigits : 2 })}&iva=${cartItem.product.vat}&importo=${importo.toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits : 2, minimumFractionDigits : 2 })}`;
          let uri = `${this.apiurl}/buyorderdetail.php?${uriParams}`;
          let insertRow = this.http.post(uri, {});
          fxQueue.push(insertRow);
        }
        // Crea righe ordine
        forkJoin(fxQueue).subscribe(resData => {
          // console.log('fxQueue results', resData);

          let scontoMaturato = 0.00;
          let scontoUtilizzato = 0.00;
          let totaleImp = 0.00;
          let totaleIva = 0.00;
          let orderedProducts: Array<Product> = [];
          
          // Recupera totali e dati per chiusura ordine
          resData.filter(ro => ro.Res === 'true').map(ap => {
            // Recupera il prodotto dal carrello
            let cartItem = cart.items.filter(p => p.product.id === ap.ProductID)[0];
            orderedProducts.push(cartItem.product);

            if (cart.checkoutDetails.useDiscountBonus) {
              scontoUtilizzato += cartItem.product.cost_price * cartItem.quantity;
            }
            
            if (cart.checkoutDetails.useDiscount30) {
              scontoMaturato += cartItem.product.cost_price * cartItem.quantity * 0.3;
            }

            totaleImp += cartItem.product.cost_price * coefficienteSconto * cartItem.quantity;
            totaleIva += cartItem.product.cost_price * coefficienteSconto *  cartItem.quantity * (cartItem.product.vat / 100.00);
          });

          // Rimuovi i prodotti dal carrello
          this.cartService.removeFromCartMultiple(orderedProducts);

          let totaleOrdine = totaleImp + totaleIva;

          orderId = this.zeroFill(orderId, 9);
          // console.log(orderId);

          let uriParams = `cod=${orderId}&su=${scontoUtilizzato.toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits : 2, minimumFractionDigits : 2 })}&sm=${scontoMaturato.toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits : 2, minimumFractionDigits : 2 })}&totImp=${totaleImp.toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits : 2, minimumFractionDigits : 2 })}&totIva=${totaleIva.toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits : 2, minimumFractionDigits : 2 })}&tot=${totaleOrdine.toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits : 2, minimumFractionDigits : 2 })}`;
          let uri = `${this.apiurl}/updateorder.php?${uriParams}`;
          this.http.post(uri, {}).subscribe((boRes: any) => {
            // console.log('updateorder', boRes);
            resolve(orderId);
          });
        }, error => {
          // Error
        });
      });
    });
  }

  getDiscountBonus(userId: string): Promise<number> {
    return new Promise<number>(resolve => {
      let discountBonus = 0.00;

      if (!!userId) {
        this.getPastOrders(userId).then(orders => {
          let totDiscountM = orders.map(order => order.discountM).reduce((a, b) => a + b, 0);
          let totDiscountU = orders.map(order => order.discountU).reduce((a, b) => a + b, 0);
          discountBonus = totDiscountM - totDiscountU;
          resolve(discountBonus);
        });
      } else {
        resolve(discountBonus);
      }
    });
  }

  toOrder(rawOrder: any): Order {
    let order: Order = {
      id: rawOrder.CodOrdine.trim(),
      date: rawOrder.Date.trim(),
      time: rawOrder.Time,
      amount: parseFloat(rawOrder.Totale),
      discountM: parseFloat(rawOrder.ScontoM),
      discountU: parseFloat(rawOrder.ScontoU),
      cart: null
    };
    return order;
  }

  toCart(rawCart: any): CartItem {
    let cart: CartItem = {
      product: {
        id: rawCart.Name.trim(),
        name: rawCart.Name.trim(),
        cost_price: parseFloat(rawCart.Price),
        total_price:  Math.round((parseFloat(rawCart.Price) + parseFloat(rawCart.Price) /100 * parseInt(rawCart.IVA))*100)/100 ,
        full_price: 0,
        vat: parseFloat(rawCart.IVA),
        discount: 0,
        offer: false,
        stock: parseInt(rawCart.OutOfStock),
        description: rawCart.Details.trim(),
        images: [
          'https://api.dolcetorino.ikigai-soluzioni.it/immagini/' + rawCart.Name.trim() + '.jpg'
        ],
        rating_count: 0,
        store_rating_count: 0,
        currency: '€',
        bought: 0,
        size: '',
        color: '',
        shipping: 0,
        rating: 0,
        store_rating: 0,
        store_rate: 0,
        sold_by: '',
        specs: '',
        reviews: [],
        store_reviews: [],
        sizing: { small: 0, okay: 0, large: 0 },
        buyer_guarantee: '',
        sponsored: [],
        quantity: parseInt(rawCart.Quantity)
      },
      quantity: parseInt(rawCart.Quantity)
    };
    return cart;
  }
}
