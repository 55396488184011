/**
* Ionic Full App  (https://store.enappd.com/product/ionic-full-app-ionic-4-full-app)
*
* Copyright © 2019-present Enappd. All rights reserved.
*
* This source code is licensed as per the terms found in the
* LICENSE.md file in the root directory of this source tree.
*/
import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { Platform, ModalController, MenuController, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { UtilService } from './services/util/util.service';
import { AuthService } from './services/auth/auth.service';
import { CartService } from './services/woocommerce/cart.service';
import { ConnectivityService } from './services/network/connectivity.service';
import { ConnectionCheckPage } from './pages/utils/connection-check/connection-check.page';
import { FCM } from '@ionic-native/fcm/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app-component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  homepageUri: string = '/e-store/categories';
  sidemenu = 1;
  showChildren = '';
  counter = 0;
  backButtonSubscription;
  public dtmenu = [];
  public isOffline: boolean = false;
  connectionCheckModal: HTMLIonModalElement;

  constructor(
    public connectivityService: ConnectivityService,
    public authService: AuthService,
    protected cartService: CartService,
    private util: UtilService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private route: Router,
    public modalCtrl: ModalController,
    private iab: InAppBrowser,
    private menuCtrl: MenuController,
    private navCtrl: NavController,
    private fcm: FCM, 
  ) {
    this.initializeApp();
  }

  async ngOnInit() {
    this.connectivityService.watchOffline().subscribe(async () => {
      if (!this.isOffline) {
        this.util.toast('Connessione mancante. Effettuare la connessione ad Internet e riprovare.');
        
        this.connectionCheckModal = await this.modalCtrl.create({
          component: ConnectionCheckPage,
          componentProps: {},
          cssClass: 'modal-fullscreen'
        });
        this.connectionCheckModal.present();

        // this.util.navigate('/connection-check', false);

        // this.util.confirm('Connessione mancante', 'Effettuare la connessione ad Internet e riprovare.', 'Riprova', 'Esci').then(res => {
        //   if (res) {
        //     // this.navCtrl. TODO
        //   } else {
        //     this.exitApp();
        //   }
        // });

        this.isOffline = true;
      }
    });

    this.connectivityService.watchOnline().subscribe(() => {
      if (this.isOffline) {
        this.util.toast('Tornato online');

        // this.util.navigate(this.homepageUri, false);

        this.connectionCheckModal.dismiss();

        this.isOffline = false;
      }
    });

    this.authService.loggedUser.subscribe(loggedUser => {
      if (!!loggedUser) {
        this.dtmenu = [
          { title: 'Chi siamo', url: '/about', icon: 'information-circle' },
          { title: 'Prodotti', url: '/e-store/categories', icon: 'filing' },
          { title: 'Ordini', url: '/e-store/orders', icon: 'pricetags' },
          { title: 'Carrello', url: '/e-store/cart', icon: 'cart' }
        ];
      } else {
        this.dtmenu = [
          { title: 'Chi siamo', url: '/about', icon: 'information-circle' },
          { title: 'Prodotti', url: '/e-store/categories', icon: 'filing' },
        ];
      }

      // this.dtmenu.push({ title: '[DEBUG] Connection check', url: '/connection-check', icon: 'information-circle' });
    });
  }

  ngAfterViewInit() {
    this.backButtonSubscription = this.platform.backButton.subscribe(() => {
      if(this.route.url === this.homepageUri) {

        if (this.counter === 0) {
          this.counter++;
          this.util.toast('Premi due volte indietro per uscire...');
          setTimeout(() => { this.counter = 0 }, 3000)
        } else {
          // console.log("exitapp");
          this.exitApp();
        }

        // this.util.confirm('Esci', 'Uscire dall\'app?').then(res => {
        //   if (res) {
        //     this.exitApp();
        //   }
        // });
      } else {
        this.navCtrl.pop();
      }
    });
  }

  ngOnDestroy() {
    this.backButtonSubscription.unsubscribe();
  }

  logout() {
    this.authService.logout().then(() => {
      this.cartService.emptyCart();
      this.util.navigate(this.homepageUri, false);
    });
  }

  login() {
    this.util.navigate('login', false);
  }

  exitApp() {
    navigator['app'].exitApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      // this.splashScreen.hide();
      this.managePushNotifications();
    });
  }

  managePushNotifications () {
    this.fcm.getToken().then(token => {
      console.log('fcm.getToken()', token);
    });
    this.fcm.onTokenRefresh().subscribe(token => {
      console.log('fcm.onTokenRefresh()', token);
    });
    this.fcm.onNotification().subscribe(data => {
      console.log('fcm.onNotification()', data);
      if (data.wasTapped) {
        console.log('Received in background');
      } else {
        this.util.toast(data.title + '\n\n' + data.body);
        console.log('Received in foreground');
      }
    });
  }

  showSidemenu(index) {
    this.sidemenu = index + 1;
  }

  expandMenu(title) {
    if (this.showChildren === title) {
      this.showChildren = '';
    } else {
      this.showChildren = title;
    }
    // console.log(this.showChildren)
  }

  async proRedirect(pageUrl) {
    // console.log(pageUrl);
    this.route.navigate([pageUrl])
    this.menuCtrl.toggle();
  }

}
